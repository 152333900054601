import React from "react";
import Page from "../components/Page";
import { Grid } from "@mui/material";
import NormalText from "../Texts/NormalText";

const Accueil: React.FC = () => {
  return (
    <Page pageTitle="Accueil">
      <Grid container direction="column" wrap="nowrap" gap="30px">
        <Grid item display="grid">
          <NormalText text="Accueil" />
        </Grid>
      </Grid>
    </Page>
  );
};

export default Accueil;
