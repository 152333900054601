import React from "react";
import "./SecondaryButton.scss";
import { Grid } from "@mui/material";

interface ISecondaryButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
  leftComponent?: JSX.Element;
}

const SecondaryButton: React.FunctionComponent<ISecondaryButton> = (
  props: ISecondaryButton
): JSX.Element => {
  const { className, style, text, onClick, leftComponent } = props;

  return (
    <button style={style} className={className} onClick={onClick}>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap="10px"
        alignItems="center"
        justifyContent="space-evenly"
      >
        {leftComponent && (
          <Grid item display="grid">
            {leftComponent}
          </Grid>
        )}
        <Grid item display="grid">
          {text}
        </Grid>
      </Grid>
    </button>
  );
};

SecondaryButton.defaultProps = {
  className: "secondary-button",
  style: undefined,
  leftComponent: undefined,
};

export default SecondaryButton;
