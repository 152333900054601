import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import makeAPIRequest from "./makeAPIRequest";
import { NetworkError } from "./authSlice";
import { IExerciseResponse } from "../pages/Exercices";

// Thunk pour récupérer toutes les exercices
const getAllExercises = createAsyncThunk(
  "/GET_ALL_EXERCISES",
  async (payload, thunkAPI): Promise<IExerciseResponse[]> => {
    try {
      const response = await makeAPIRequest("get", "/api/v1/exercises/all");
      return thunkAPI.fulfillWithValue(response.data?.exercises) as any;
    } catch (err) {
      return thunkAPI.rejectWithValue(err) as any;
    }
  }
);

// Thunk pour supprimer une exercice
const deleteExercise = createAsyncThunk<
  string,
  string,
  {
    rejectValue: any;
  }
>("/DELETE_EXERCISE", async (payload, thunkAPI): Promise<string> => {
  try {
    await makeAPIRequest("delete", `/api/v1/exercises/${payload}`);
    return thunkAPI.fulfillWithValue(payload) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Thunk pour récupérer les exercices favorites
const getFavoriteExercises = createAsyncThunk(
  "/GET_FAVORITE_EXERCISES",
  async (payload, thunkAPI): Promise<IExerciseResponse[]> => {
    try {
      const response = await makeAPIRequest(
        "get",
        "/api/v1/exercises/favorite"
      );
      return thunkAPI.fulfillWithValue(response.data?.exercises) as any;
    } catch (err) {
      return thunkAPI.rejectWithValue(err) as any;
    }
  }
);

// Thunk pour cliquer sur un exercice favoris
const clickFavoriteExercise = createAsyncThunk<
IExerciseResponse,
  string,
  {
    rejectValue: any;
  }
>("/CLICK_FAVORITE", async (payload, thunkAPI): Promise<IExerciseResponse> => {
  try {
    const response = await makeAPIRequest(
      "get",
      `/api/v1/exercises/${payload}/favorite`
    );
    return thunkAPI.fulfillWithValue(response.data?.exercise) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Thunk pour créer un nouvel exercice
const createExercise = createAsyncThunk<
  IExerciseResponse,
  Partial<IExerciseResponse>,
  {
    rejectValue: any;
  }
>("/CREATE_EXERCISE", async (payload, thunkAPI): Promise<IExerciseResponse> => {
  try {
    const response = await makeAPIRequest("post", "/api/v1/exercises", payload);
    return thunkAPI.fulfillWithValue(response.data) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Thunk pour modifier un exercice existant
const updateExercise = createAsyncThunk<
  IExerciseResponse,
  { id: string; data: Partial<IExerciseResponse> },
  {
    rejectValue: any;
  }
>("/UPDATE_EXERCISE", async (payload, thunkAPI): Promise<IExerciseResponse> => {
  try {
    const { id, data } = payload;
    const response = await makeAPIRequest(
      "put",
      `/api/v1/exercises/${id}`,
      data
    );
    return thunkAPI.fulfillWithValue(response.data) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Thunk pour modifier un exercice existant
const getExercise = createAsyncThunk<
  IExerciseResponse,
  { id: string },
  {
    rejectValue: any;
  }
>("/GET_EXERCISE", async (payload, thunkAPI): Promise<IExerciseResponse> => {
  try {
    const { id } = payload;
    const response = await makeAPIRequest(
      "get",
      `/api/v1/exercise/${id}`,
      null
    );
    return thunkAPI.fulfillWithValue(response.data) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Interface du state des exercices
export interface ExercisesState {
  exercises: IExerciseResponse[];
  favorites: IExerciseResponse[];
  isLoading: boolean;
  error: NetworkError | undefined;
  success: boolean;
}

// État initial du slice
const initialState: ExercisesState = {
  exercises: [],
  favorites: [],
  isLoading: false,
  error: undefined,
  success: false,
};

// Slice pour les exercices
export const exercisesSlice = createSlice({
  name: "Exercises",
  initialState,
  reducers: {
    updateExercise: (state, action) => {
      state.exercises = state.exercises.map((x) =>
        x?._id === action.payload._id ? action.payload : x
      );
    },
    updateStep: (state, action) => {
      state.exercises = state.exercises.map((exercise) => ({
        ...exercise,
        steps: exercise.steps.map((step) =>
          step?._id === action.payload._id ? action.payload : step
        ),
      }));
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<ExercisesState>) => {
    // Delete exercice
    builder.addCase(deleteExercise.fulfilled, (state, action) => {
      state.exercises = state.exercises.filter((x) => x?._id !== action.payload);
    });

    // Click favorite
    builder.addCase(clickFavoriteExercise.fulfilled, (state, action) => {
      state.exercises = state.exercises.map((x) =>
        x?._id === action.payload._id ? action.payload : x
      );
      state.favorites = state.favorites.map((x) =>
        x?._id === action.payload._id ? action.payload : x
      );
    });

    // Get favorites
    builder.addCase(getFavoriteExercises.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFavoriteExercises.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.length > 0) {
        state.favorites = action.payload;
      }
      state.success = true;
    });
    builder.addCase(getFavoriteExercises.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });

    // Get exercises
    builder.addCase(getAllExercises.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllExercises.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.length > 0) {
        state.exercises = action.payload;
      }
      state.success = true;
    });
    builder.addCase(getAllExercises.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });

    // Create exercice
    builder.addCase(createExercise.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createExercise.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exercises.push(action.payload);
      state.success = true;
    });
    builder.addCase(createExercise.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });

    // Update exercice
    builder.addCase(updateExercise.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateExercise.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exercises = state.exercises.map((x) =>
        x?._id === action.payload._id ? action.payload : x
      );
      state.success = true;
    });
    builder.addCase(updateExercise.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });
  },
});

export const exercisesActions = exercisesSlice.actions;

export {
  getAllExercises,
  clickFavoriteExercise,
  getFavoriteExercises,
  deleteExercise,
  createExercise,
  updateExercise,
  getExercise
};

export default exercisesSlice.reducer;
