import React from "react";
import "./NormalText.scss";
import Colors, { ColorList } from "../utils/Colors";

interface IText {
  text: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  color?: ColorList;
  fontWeight?: number;
  fontStyle?: "italic" | "normal";
  fontSize?: string;
  maxTextLength?: number;
}

const NormalText: React.FC<IText> = (props: IText): JSX.Element => {
  const {
    className,
    text,
    style,
    onClick,
    color,
    fontWeight,
    fontStyle,
    fontSize,
    maxTextLength,
  } = props;

  let finalText = text;
  const overrideStyles: any = {};
  if (color && Colors?.[color]) {
    overrideStyles.color = Colors[color];
  }
  if (fontWeight) {
    overrideStyles.fontWeight = fontWeight;
  }
  if (fontStyle) {
    overrideStyles.fontStyle = fontStyle;
  }
  if (fontSize) {
    overrideStyles.fontSize = fontSize;
  }

  if (maxTextLength && maxTextLength < text.length) {
    finalText = finalText.substring(0, maxTextLength);
    if (finalText.endsWith(" ")) {
      finalText = finalText.substring(0, finalText.length - 1);
    }
    finalText += "..."
  }

  return (
    <span
      className={`${className}${onClick ? ` opacity-animation` : ``}`}
      style={{ ...style, ...overrideStyles }}
      onClick={onClick}
    >
      {finalText}
    </span>
  );
};

NormalText.defaultProps = {
  className: "normal-text",
  style: undefined,
  onClick: undefined,
  color: "White",
  fontWeight: 400,
  fontSize: "16px",
  fontStyle: "normal",
  maxTextLength: undefined,
};

export default NormalText;
