import React from "react";
import Page from "../components/Page";
import { Grid } from "@mui/material";
import NormalText from "../Texts/NormalText";

const NotFound: React.FC = () => {
  return (
    <Page pageTitle="NotFound" hideFooter={true}>
      <Grid container direction="column" wrap="nowrap" gap="5px" marginTop="20px">
        <Grid item display="grid" justifyContent={"center"}>
            <NormalText text="404" fontSize="20px" fontWeight={600} color="White" />
        </Grid>
        <Grid item display="grid" justifyContent={"center"}>
            <NormalText text="Page introuvable" fontSize="20px" fontWeight={600} color="White" />
        </Grid>
      </Grid>
    </Page>
  );
};

export default NotFound;
