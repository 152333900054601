import React from "react";
import Page from "../components/Page";
import { CircularProgress, Grid } from "@mui/material";
import { DrawCard, DrawExerciseCard } from "./Lecons";
import { clickFavorite, getFavoriteLecons } from "../redux/leconsSlice";
import useOpenSnackbar from "../redux/useOpenSnackbar";
import useAppDispatch from "../redux/useAppDispatch";
import useAppSelector from "../redux/useAppSelector";
import NormalText from "../Texts/NormalText";
import Colors from "../utils/Colors";
import {
  clickFavoriteExercise,
  getFavoriteExercises,
} from "../redux/exercisesSlice";
import { IExercise } from "./Exercices";
import { useNavigate } from "react-router-dom";
import useQuery from "../redux/useQuery";

const LeconsFavoris: React.FC = () => {
  const snackbarUse = useOpenSnackbar();
  const dispatch = useAppDispatch();
  const cards = useAppSelector((x) => x.lecons.favorites);
  const pageLoading = useAppSelector((x) => x.lecons.isLoading);
  const filteredCards = cards.filter((x) => x.favorite === true);

  React.useEffect(() => {
    dispatch(getFavoriteLecons())
      .unwrap()
      .then((res) => {
        return;
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer les leçons favoris.");
      })
      .finally(() => {
        return;
      });
  }, [dispatch]);

  return (
    <Grid container direction="row" gap="20px" justifyContent={"space-evenly"}>
      {pageLoading ? (
        <Grid item display="grid" paddingTop="60px">
          <CircularProgress
            style={{
              color: "white",
            }}
          />
        </Grid>
      ) : (
        <>
          {filteredCards.length === 0 ? (
            <Grid item display="grid" paddingTop="20px">
              <NormalText
                text="Vous n'avez aucune leçon en favoris."
                fontSize="16px"
                color="White"
              />
            </Grid>
          ) : (
            <>
              {filteredCards.map((card) => {
                return (
                  <Grid item key={card._id} display="grid">
                    <DrawCard
                      card={card}
                      onFavoriteClick={() => {
                        if (card?._id) {
                          dispatch(clickFavorite(card._id));
                        }
                      }}
                    />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

const ExercicesFavoris: React.FC = () => {
  const snackbarUse = useOpenSnackbar();
  const dispatch = useAppDispatch();
  const exercices = useAppSelector((x) => x.exercises.favorites);
  const pageLoading = useAppSelector((x) => x.exercises.isLoading);
  const filteredCards = exercices.filter((x) => x.favorite === true);

  React.useEffect(() => {
    dispatch(getFavoriteExercises())
      .unwrap()
      .then((res) => {
        return;
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer les exercices favoris.");
      })
      .finally(() => {
        return;
      });
  }, [dispatch]);

  return (
    <Grid container direction="row" gap="20px" justifyContent={"space-evenly"}>
      {pageLoading ? (
        <Grid item display="grid" paddingTop="60px">
          <CircularProgress
            style={{
              color: "white",
            }}
          />
        </Grid>
      ) : (
        <>
          {filteredCards.length === 0 ? (
            <Grid item display="grid" paddingTop="20px">
              <NormalText
                text="Vous n'avez aucun exercice en favoris."
                fontSize="16px"
                color="White"
              />
            </Grid>
          ) : (
            <>
              {filteredCards.map((card) => {
                return (
                  <Grid item key={card._id} display="grid">
                    <DrawExerciseCard
                      card={card}
                      onEditClick={undefined}
                      onDeleteClick={undefined}
                      onFavoriteClick={() => {
                        if (card?._id) {
                          dispatch(clickFavoriteExercise(card._id));
                        }
                      }}
                    />
                  </Grid>
                );
              })}
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export const onglets = ["Leçons", "Exercices"] as const;
export type Onglet = (typeof onglets)[number];
const Favoris: React.FC = () => {
  const defaultOnglet: Onglet = "Leçons"
  const [actualOnglet, setActualOnglet] = React.useState<Onglet>(defaultOnglet);
  const navigate = useNavigate();
  const onglet = useQuery().get("onglet");

  React.useEffect(() => {
    if (!onglet) {
      setActualOnglet(defaultOnglet);
    }
    if (onglet && onglets.includes(onglet as Onglet)) {
      setActualOnglet(onglet as Onglet);
    }
  }, [onglet]);

  return (
    <Page
      pageTitle="Favoris"
      header={
        <Grid
          container
          direction="row"
          wrap="nowrap"
          padding="12px 3px"
          alignItems={"center"}
          justifyContent={"space-evenly"}
          style={{
            backgroundColor: Colors.Black,
            borderBottom: "1px solid rgba(105, 105, 105, 0.5)",
          }}
        >
          {onglets.map((onglet) => {
            return (
              <Grid item xs display="grid">
                <NormalText
                  fontSize="10px"
                  text={onglet}
                  color="White"
                  fontStyle="normal"
                  fontWeight={400}
                  onClick={() => {
                    setActualOnglet(onglet);
                    navigate(`/favoris?onglet=${onglet}`);
                  }}
                  style={{
                    whiteSpace: "nowrap",
                    opacity: actualOnglet === onglet ? 1 : 0.4,
                    textAlign: "center",
                    userSelect: "none",
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      }
    >
      {actualOnglet === "Leçons" && <LeconsFavoris />}
      {actualOnglet === "Exercices" && <ExercicesFavoris />}
    </Page>
  );
};

export default Favoris;
