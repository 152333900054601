import { Grid } from "@mui/material";
import React from "react";
import "./BasicInput.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ButtonIconWithTooltip from "./ButtonIconWithTooltip";
import NormalText from "../Texts/NormalText";
import Colors, { ColorList } from "../utils/Colors";

export interface IBasicInput
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  style?: React.CSSProperties;
  hasError?: boolean;
  displayError?: boolean;
  errorMessage?: string;
  title?: string;
  errorMessageMaxWidth?: number;
  colorTitle?: ColorList;
  centerTitle?: boolean;
}

const BasicInput: React.FunctionComponent<IBasicInput> = (
  props: IBasicInput
): JSX.Element => {
  const { className, style, errorMessageMaxWidth, colorTitle, centerTitle } = props;
  const haveToDisplayError =
    props.hasError && props.errorMessage && props.displayError;
  const [displayPassword, setDisplayPassword] = React.useState<boolean>(false);
  const newStyleComputed = { ...style };

  return (
    <Grid container direction="column" wrap="nowrap" gap="5px" alignItems={centerTitle ? "center" : undefined}>
      {props.title && (
        <Grid item display="grid" marginLeft="5px">
          <NormalText text={props.title} color={colorTitle ? colorTitle : "White"} fontSize="14px" />
        </Grid>
      )}
      <Grid item display="grid">
        <Grid container direction="column" wrap="nowrap">
          <Grid item display="grid">
            <>
              <div
                style={{
                  position: "relative",
                }}
              >
                <input
                  {...props}
                  type={displayPassword ? "text" : props.type}
                  style={newStyleComputed}
                  className={`${className}${
                    haveToDisplayError ? " basic-input-error" : ""
                  }`}
                />
                {props.type === "password" && (
                  <div
                    style={{
                      position: "absolute",
                      right: -10,
                      top: 10,
                    }}
                  >
                    <ButtonIconWithTooltip
                      tooltipText={
                        displayPassword ? "Cacher le mot de passe" : "Afficher le mot de passe"
                      }
                      onClick={() => {
                        setDisplayPassword((x) => (x ? false : true));
                      }}
                      Icon={
                        displayPassword ? (
                          <VisibilityOff
                            sx={{
                              color: Colors.Black,
                            }}
                          />
                        ) : (
                          <Visibility
                            sx={{
                              color: Colors.Black,
                            }}
                          />
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </>
          </Grid>
          {haveToDisplayError && (
            <Grid
              item
              display="grid"
              paddingTop="5px"
              marginLeft="5px"
              width={errorMessageMaxWidth}
            >
              <NormalText
                color="RedError"
                fontSize="12px"
                text={props.errorMessage as string}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

BasicInput.defaultProps = {
  className: "basic-input",
  style: undefined,
  hasError: false,
  displayError: false,
  errorMessage: "",
  title: undefined,
  errorMessageMaxWidth: 200,
};

export default BasicInput;
