import { IconButton, Tooltip } from '@mui/material'
import React from 'react'

const ButtonIconWithTooltip: React.FC<{
    Icon: JSX.Element
    onClick: (e: any) => void
    tooltipText: string
}> = (props) => {
    return (
        <Tooltip title={props.tooltipText}>
            <IconButton
                style={{
                    margin: 0,
                    padding: 0,
                }}
                onClick={props.onClick}>
                {props.Icon}
            </IconButton>
        </Tooltip>
    )
}

export default ButtonIconWithTooltip
