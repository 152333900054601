
const Colors = {
  White: "#FFF",
  Black: "#000",
  RedError: "red",
  Grey: "rgba(105, 105, 105, 1)",
  Blue: "#0041c2",
  DarkGrey: "#292929",
};

export type ColorList = keyof typeof Colors;

export default Colors;
