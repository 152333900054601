import React from "react";
import Page from "../components/Page";
import { CircularProgress, Grid } from "@mui/material";
import NormalText from "../Texts/NormalText";
import useAppDispatch from "../redux/useAppDispatch";
import useAppSelector from "../redux/useAppSelector";
import useOpenSnackbar from "../redux/useOpenSnackbar";
import { getExercise } from "../redux/exercisesSlice";
import { useNavigate, useParams } from "react-router-dom";
import {
  ExerciseStep,
  IExerciseResponse,
  QuestionLabel,
  ResponseLabel,
} from "./Exercices";
import { DisplayAudio, getDurationFromSeconds } from "./Lecons";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton/SecondaryButton";
import {
  Done,
  EmojiEvents,
  EmojiEventsOutlined,
  Home,
  Pause,
  PlayArrow,
  Replay,
  SkipNext,
  Undo,
} from "@mui/icons-material";
import Colors from "../utils/Colors";
import ButtonIconWithTooltip from "../components/ButtonIconWithTooltip";

import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material/styles";
import MySwitch from "../components/MySwitch";
import BasicSelect from "../components/BasicSelect";
import makeAPIRequest from "../redux/makeAPIRequest";

const CustomButton = styled(Button)(({ theme }) => ({
  color: "white", // couleur des boutons actifs
  fontSize: "12px",
  "&.Mui-disabled": {
    color: "gray", // couleur des boutons désactivés
  },
}));

const CustomMobileStepper = styled(MobileStepper)(({ theme }) => ({
  "& .MuiMobileStepper-dot": {
    backgroundColor: "gray", // couleur des dots inactifs
  },
  "& .MuiMobileStepper-dotActive": {
    backgroundColor: "white", // couleur des dots actifs
  },
}));

export const DotsMobileStepper: React.FC<{
  stepIndex: number;
  onNextClick: () => void;
  onPreviousClick: () => void;
  canClickNext: boolean;
  nbSteps: number;
}> = (props) => {
  const theme = useTheme();
  const { onNextClick, onPreviousClick, nbSteps, stepIndex, canClickNext } =
    props;

  const handleNext = () => {
    onNextClick();
  };

  const handleBack = () => {
    onPreviousClick();
  };

  return (
    <CustomMobileStepper
      variant="dots"
      steps={nbSteps}
      position="static"
      activeStep={stepIndex}
      sx={{ width: "100%", minWidth: "300px", backgroundColor: "transparent" }}
      nextButton={
        <CustomButton
          size="small"
          onClick={handleNext}
          disabled={stepIndex > nbSteps - 1 || !canClickNext}
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
          {nbSteps <= 10 && `SUIVANT`}
        </CustomButton>
      }
      backButton={
        <CustomButton
          size="small"
          onClick={handleBack}
          disabled={stepIndex === 0}
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          {nbSteps <= 10 && `PRÉCÉDENT`}
        </CustomButton>
      }
    />
  );
};

const TimerExercise: React.FC<{
  step: ExerciseStep;
  onNextStepClick: () => void;
  remainingTime: number | undefined;
  setRemainingTime: React.Dispatch<React.SetStateAction<number | undefined>>;
  autoStart?: boolean;
}> = (props) => {
  const { step, onNextStepClick, remainingTime, setRemainingTime, autoStart } =
    props;
  const [progression, setProgression] = React.useState<
    "not-started" | "in progress" | "finished" | "paused"
  >("not-started");
  const stepId = step?._id;
  const stepDuration = step?.timer?.duration;
  const intervalId = React.useRef<NodeJS.Timeout | null>(null); // UseRef pour éviter le re-render

  React.useEffect(() => {
    if (autoStart) {
      setProgression("in progress");
    } else {
      setProgression("not-started");
    }
    setRemainingTime(stepDuration ? stepDuration : undefined);
  }, [stepId, stepDuration, autoStart]);

  const endTimer = () => {
    setProgression("finished");
  };

  // Démarrer le compte à rebours
  React.useEffect(() => {
    if (remainingTime === undefined || remainingTime <= 0) return;

    if (progression === "in progress") {
      if (intervalId.current) clearInterval(intervalId.current); // Nettoyer l'intervalle précédent
      intervalId.current = setInterval(() => {
        setRemainingTime((prev) => (prev !== undefined ? prev - 1 : undefined));
      }, 1000);
    } else {
      if (intervalId.current) clearInterval(intervalId.current);
    }

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, [progression, remainingTime]);

  React.useEffect(() => {
    if (remainingTime === 0) {
      onNextStepClick();
      clearInterval(intervalId.current!);
      setProgression("finished");
      endTimer();
    }
  }, [remainingTime]);

  const progressValue =
    remainingTime !== undefined && step?.timer?.duration !== undefined
      ? (remainingTime / step.timer.duration) * 100
      : undefined;

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <>
      {step?.timer?.instructions && (
        <Grid item display="grid" alignSelf={"center"}>
          <NormalText
            text={step.timer.instructions}
            color="White"
            fontSize="14px"
            fontWeight={400}
            style={{ textAlign: "left" }}
          />
        </Grid>
      )}
      <Grid item display="grid" paddingTop="10px" alignSelf={"center"}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          gap="40px"
          minHeight="80px"
          width="230px"
          alignItems={"center"}
        >
          <Grid item display="grid">
            <SecondaryButton
              style={{ width: "150px" }}
              leftComponent={
                {
                  "not-started": (
                    <PlayArrow
                      style={{
                        color: Colors.Black,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ),
                  "in progress": (
                    <Pause
                      style={{
                        color: Colors.Black,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ),
                  paused: (
                    <PlayArrow
                      style={{
                        color: Colors.Black,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ),
                  finished: (
                    <Done
                      style={{
                        color: Colors.Black,
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  ),
                }?.[progression]
              }
              text={
                {
                  "not-started": "Lancer",
                  "in progress": "En cours",
                  paused: "Reprendre",
                  finished: "Terminé",
                }?.[progression]
              }
              onClick={() => {
                if (progression === "not-started") {
                  setProgression("in progress");
                } else if (progression === "in progress") {
                  setProgression("paused");
                } else if (progression === "finished") {
                  onNextStepClick();
                } else if (progression === "paused") {
                  setProgression("in progress");
                }
              }}
            />
          </Grid>
          <Grid item display="grid">
            {(progression === "in progress" || progression === "paused") &&
              remainingTime !== undefined && (
                <>
                  <Grid
                    container
                    direction="column"
                    wrap="nowrap"
                    gap="5px"
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    {/* Cercle de progression du compte à rebours */}
                    <Grid item display="grid">
                      <CircularProgress
                        variant="determinate"
                        value={progressValue}
                        size={25}
                        thickness={5}
                        sx={{
                          color: Colors.White,
                          transform: "rotate(180deg)",
                        }} // Inverser le sens
                      />
                    </Grid>
                    {/* Texte du compte à rebours */}
                    <Grid item display="grid">
                      <NormalText
                        text={formatTime(remainingTime)}
                        color="White"
                        fontSize="14px"
                        fontWeight={400}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            {progression === "not-started" && step?.timer?.duration && (
              <NormalText
                text={formatTime(step.timer.duration)}
                color="White"
                fontSize="14px"
                fontWeight={600}
                style={{ textAlign: "center" }}
              />
            )}
            {progression === "finished" && (
              <ButtonIconWithTooltip
                onClick={() => onNextStepClick()}
                tooltipText=""
                Icon={
                  <SkipNext
                    style={{
                      color: Colors.Black,
                      backgroundColor: Colors.White,
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      padding: "5px",
                    }}
                  />
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const getAllLabelsFromStep = (
  questionLabels: QuestionLabel[]
): ResponseLabel[] => {
  const result: ResponseLabel[] = [];
  questionLabels.forEach((a) => {
    a.labels.forEach((b) => {
      result.push(b);
    });
  });
  return result;
};

const Exercice: React.FC = () => {
  const dispatch = useAppDispatch();
  const [exerciseLoading, setExerciseLoading] = React.useState<boolean>(false);
  const [exercise, setExercise] = React.useState<IExerciseResponse | undefined>(
    undefined
  );
  const [declenchAudio, setDeclenchAudio] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const snackbarUse = useOpenSnackbar();
  const params = useParams<{
    id: string;
  }>();
  const [completedInSec, setCompletedInSec] = React.useState<number>(0);
  const exerciseId = params?.id;
  const [stepIndex, setStepIndex] = React.useState<number>(0);
  const actualStep =
    exercise && stepIndex >= 1 ? exercise?.steps?.[stepIndex - 1] : undefined;
  const nbSteps = exercise?.steps?.length ? exercise.steps.length + 2 : 2;
  const [activatedResponses, setActivatedResponses] = React.useState<string[]>(
    []
  );
  const [remainingTime, setRemainingTime] = React.useState<number | undefined>(
    undefined
  );
  const [finishedAudio, setFinishedAudio] = React.useState<boolean>(false);
  const [alreadyFinished, setAlreadyFinished] = React.useState<boolean>(false);

  const sendFinishedToServer = async () => {
    setAlreadyFinished(true);
    await makeAPIRequest("post", `/api/v1/exercise/complete`, {
      completedInSec: completedInSec,
      responses: activatedResponses,
      exerciseId: exerciseId,
    });
  };

  const goNext = async () => {
    if (stepIndex === nbSteps - 2 && !alreadyFinished) {
      await sendFinishedToServer();
    }
    setStepIndex((x) => x + 1);
    setFinishedAudio(false);
    setRemainingTime(undefined);
  };

  const goBack = () => {
    setStepIndex((x) => x - 1);
    setRemainingTime(undefined);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCompletedInSec((x) => x + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const resetAll = () => {
    setStepIndex(0);
    setRemainingTime(undefined);
    setActivatedResponses([]);
    setAlreadyFinished(false);
  };

  React.useEffect(() => {
    if (!exerciseId) {
      return;
    }
    setExerciseLoading(true);
    dispatch(
      getExercise({
        id: exerciseId,
      })
    )
      .unwrap()
      .then((data) => {
        setExercise(data);
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer cet exercice.");
      })
      .finally(() => {
        setExerciseLoading(false);
      });
  }, [dispatch, exerciseId]);

  return (
    <Page
      pageTitle="Exercices"
      maxWidth="600px"
      noPadding={true}
      fullHeight={true}
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item display="grid" height="100%">
          {exerciseLoading ? (
            <div
              style={{ justifySelf: "center", flex: 1, alignSelf: "center" }}
            >
              <CircularProgress
                style={{
                  color: "white",
                }}
              />
            </div>
          ) : exercise ? (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              maxHeight={"700px"}
              height="100%"
              alignSelf={"center"}
            >
              <Grid
                item
                display="grid"
                paddingTop="20px"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  gap="20px"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    <PrimaryButton
                      onClick={() => {
                        navigate(`/exercices`);
                      }}
                      text="QUITTER"
                      style={{
                        fontSize: "10px",
                        height: "25px",
                        fontWeight: "bold",
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <NormalText
                      text={`Exercice ${exercise?.title}`}
                      color="White"
                      fontSize="20px"
                      fontWeight={700}
                      style={{ textAlign: "center" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs
                display="grid"
                style={{ overflowY: "auto" }}
                padding="20px"
              >
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  gap="10px"
                  justifyContent={"center"}
                >
                  <Grid item display="grid">
                    {stepIndex < nbSteps - 1 && stepIndex > 0 && (
                      <Grid item display="grid" justifySelf={"flex-end"}>
                        <NormalText
                          text={`Passer >>`}
                          color="White"
                          fontSize="14px"
                          onClick={() => {
                            goNext();
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item display="grid">
                    <Grid
                      container
                      direction="column"
                      wrap="nowrap"
                      padding="20px"
                      justifySelf={"center"}
                      alignSelf={"center"}
                      style={{
                        backgroundColor: "rgba(255,255,255,0.05)",
                        borderRadius: "10px",
                      }}
                    >
                      {/*<Grid item display="grid">
                    <Grid container direction="column" wrap="nowrap" gap="10px">
                      {stepIndex < nbSteps && stepIndex > 0 && (
                        <Grid item display="grid" justifySelf={"flex-end"}>
                          <NormalText
                            text={`Passer >>`}
                            color="White"
                            fontSize="14px"
                            onClick={() => {
                              goNext();
                            }}
                          />
                        </Grid>
                      )}*/}
                      {stepIndex !== 0 && stepIndex !== nbSteps - 1 && (
                        <>
                          {actualStep?.type === "timer" && (
                            <TimerExercise
                              step={actualStep}
                              autoStart={true}
                              onNextStepClick={() => {
                                goNext();
                              }}
                              remainingTime={remainingTime}
                              setRemainingTime={setRemainingTime}
                            />
                          )}
                          {actualStep?.type === "switch" && (
                            <>
                              <Grid
                                container
                                direction="row"
                                justifyContent={"flex-start"}
                                gap="20px"
                              >
                                {actualStep?.questionLabels?.map((x) => {
                                  return (
                                    <Grid item display="grid" key={x._id}>
                                      <Grid
                                        container
                                        direction="column"
                                        wrap="nowrap"
                                        gap="10px"
                                      >
                                        <Grid item display="grid">
                                          <NormalText
                                            text={x.question}
                                            fontSize="14px"
                                            color="White"
                                            fontWeight={400}
                                          />
                                        </Grid>
                                        <Grid item display="grid">
                                          <Grid
                                            container
                                            direction="column"
                                            wrap="nowrap"
                                            gap="5px"
                                          >
                                            {x.labels.map((label) => {
                                              return (
                                                <Grid
                                                  item
                                                  display="grid"
                                                  key={label._id}
                                                >
                                                  <Grid
                                                    container
                                                    direction="row"
                                                    wrap="nowrap"
                                                    gap="10px"
                                                    alignItems={"center"}
                                                  >
                                                    <Grid item display="grid">
                                                      <MySwitch
                                                        checked={activatedResponses.includes(
                                                          label._id as string
                                                        )}
                                                        handleChange={() => {
                                                          setActivatedResponses(
                                                            (state) => {
                                                              if (
                                                                state.includes(
                                                                  label._id as string
                                                                )
                                                              ) {
                                                                return state.filter(
                                                                  (f) =>
                                                                    f !==
                                                                    label._id
                                                                );
                                                              }
                                                              return [
                                                                ...state,
                                                                label._id as string,
                                                              ];
                                                            }
                                                          );
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid item display="grid">
                                                      <NormalText
                                                        text={label.response}
                                                        color="White"
                                                        fontSize="12px"
                                                        fontWeight={400}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              );
                                            })}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </>
                          )}
                          {actualStep?.type === "audio" && (
                            <>
                              {actualStep.audio && (
                                <Grid item display="grid" alignSelf={"center"}>
                                  <DisplayAudio
                                    startAuto={true}
                                    onAudioEnd={() => {
                                      setFinishedAudio(true);
                                      goNext();
                                    }}
                                    audio={actualStep.audio}
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                          {actualStep?.type === "liste_responses" && (
                            <>
                              <Grid
                                container
                                direction="row"
                                justifyContent={"flex-start"}
                                gap="20px"
                              >
                                {actualStep?.questionLabels?.map((x) => {
                                  return (
                                    <Grid item display="grid" key={x._id}>
                                      <Grid
                                        container
                                        direction="column"
                                        wrap="nowrap"
                                        gap="10px"
                                      >
                                        <Grid item display="grid">
                                          <NormalText
                                            text={x.question}
                                            fontSize="14px"
                                            color="White"
                                            fontWeight={400}
                                          />
                                        </Grid>
                                        <Grid item display="grid">
                                          <BasicSelect
                                            options={x.labels.map((y) => {
                                              return {
                                                value: y?._id as string,
                                                label: y?.response,
                                              };
                                            })}
                                            title=""
                                            colorTitle={"Black"}
                                            placeholder="Réponse"
                                            centerTitle={true}
                                            value={(() => {
                                              const options = x.labels.map(
                                                (y) => {
                                                  return {
                                                    value: y?._id as string,
                                                    label: y?.response,
                                                  };
                                                }
                                              );
                                              const founded = options.find(
                                                (o) =>
                                                  activatedResponses.includes(
                                                    o.value
                                                  )
                                                    ? true
                                                    : false
                                              );
                                              console.log("FOUNDED :");
                                              console.log(founded?.value);
                                              return founded?.value
                                                ? founded.value
                                                : "";
                                            })()}
                                            onChange={(e) => {
                                              const allIds = x?.labels?.map(
                                                (y) => y?._id
                                              );
                                              setActivatedResponses((state) => {
                                                return e?.target?.value
                                                  ? [
                                                      ...state.filter(
                                                        (s) =>
                                                          !allIds.includes(s)
                                                      ),
                                                      e.target.value,
                                                    ]
                                                  : state.filter(
                                                      (s) => !allIds.includes(s)
                                                    );
                                              });
                                            }}
                                          />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </>
                          )}
                        </>
                      )}
                      {stepIndex === nbSteps - 1 && (
                        <>
                          {exercise.congratulationMessage && (
                            <Grid item display="grid">
                              <NormalText
                                text={exercise.congratulationMessage}
                                fontSize="14px"
                                fontWeight={400}
                                style={{ textAlign: "center" }}
                              />
                            </Grid>
                          )}
                          <Grid
                            item
                            display="grid"
                            paddingTop="20px"
                            alignSelf={"center"}
                          >
                            <EmojiEventsOutlined
                              style={{
                                color: Colors.White,
                                fontSize: "70px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            display="grid"
                            paddingTop="20px"
                            alignSelf={"center"}
                          >
                            <Grid
                              container
                              direction="row"
                              wrap="nowrap"
                              gap="10px"
                            >
                              <Grid item display="grid">
                                <SecondaryButton
                                  text="Relancer"
                                  leftComponent={
                                    <Replay
                                      style={{
                                        color: Colors.Black,
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                  onClick={() => {
                                    resetAll();
                                  }}
                                />
                              </Grid>
                              <Grid item display="grid">
                                <SecondaryButton
                                  text="Retour"
                                  leftComponent={
                                    <Home
                                      style={{
                                        color: Colors.Black,
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  }
                                  onClick={() => {
                                    navigate("/exercices");
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                      {stepIndex === 0 && (
                        <>
                          {exercise?.description && (
                            <Grid item display="grid" paddingTop="10px">
                              <NormalText
                                text={exercise.description}
                                color="White"
                                fontSize="14px"
                                fontWeight={400}
                                style={{ textAlign: "left" }}
                              />
                            </Grid>
                          )}
                          {exercise?.startAudio?.audio && (
                            <Grid
                              item
                              display="grid"
                              paddingTop="20px"
                              alignSelf={"center"}
                            >
                              <DisplayAudio
                                audio={{
                                  ...exercise.startAudio,
                                  title: exercise?.startAudio?.title
                                    ? exercise.startAudio.title
                                    : "Explications",
                                }}
                                onAudioEnd={() => setStepIndex(1)}
                                startAuto={declenchAudio}
                              />
                            </Grid>
                          )}

                          <Grid
                            item
                            display="grid"
                            paddingTop="20px"
                            alignSelf={"center"}
                          >
                            <SecondaryButton
                              text={"Commencer"}
                              style={{
                                cursor: declenchAudio ? "not-allowed" : "pointer",
                              }}
                              onClick={declenchAudio ? () => {
                                return;
                              } : () => {
                                if (exercise?.startAudio?.audio) {
                                  setDeclenchAudio(true);
                                } else {
                                  setStepIndex(1);
                                }
                              }}
                            />
                          </Grid>
                          {exercise.durationEstimation && (
                            <Grid
                              item
                              display="grid"
                              paddingTop="20px"
                              alignSelf={"center"}
                            >
                              <NormalText
                                text={
                                  "Temps estimé " +
                                  getDurationFromSeconds(
                                    exercise.durationEstimation
                                  )
                                }
                                color="White"
                                fontSize="14px"
                                fontWeight={400}
                                style={{ textAlign: "center" }}
                              />
                            </Grid>
                          )}
                          <Grid
                            item
                            display="grid"
                            paddingTop="20px"
                            alignSelf={"center"}
                          >
                            <SecondaryButton
                              text={"Annuler"}
                              leftComponent={
                                <Undo
                                  style={{
                                    color: Colors.Black,
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              }
                              onClick={() => {
                                navigate("/exercices");
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {nbSteps <= 20 && (
                <Grid item display="grid" alignSelf="center">
                  <DotsMobileStepper
                    canClickNext={(() => {
                      if (stepIndex === 0) {
                        return true;
                      }
                      if (actualStep?.type === "audio") {
                        if (finishedAudio) {
                          return true;
                        }
                        return false;
                      }
                      if (actualStep?.type === "timer") {
                        if (remainingTime === 0) {
                          return true;
                        }
                        return false;
                      } else if (
                        actualStep?.type === "switch" ||
                        actualStep?.type === "liste_responses"
                      ) {
                        for (const questionLabel of actualStep.questionLabels) {
                          if (
                            !questionLabel.labels.some((x) =>
                              activatedResponses.includes(x?._id as string)
                            )
                          ) {
                            return false;
                          }
                        }
                        return true;
                      }
                      return false;
                    })()}
                    stepIndex={stepIndex}
                    onNextClick={() => goNext()}
                    onPreviousClick={() => goBack()}
                    nbSteps={nbSteps}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <NormalText
              text={"Cet exercice n'existe pas."}
              color="White"
              fontSize="12px"
              fontWeight={400}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};

export default Exercice;
