import React from "react";
import Page from "../components/Page";
import { Grid } from "@mui/material";
import useGenericForm from "../redux/useGenericForm";
import useAppSelector from "../redux/useAppSelector";
import useAppDispatch from "../redux/useAppDispatch";
import { getUser } from "../redux/userSlice";
import useOpenSnackbar from "../redux/useOpenSnackbar";
import BasicInput from "../components/BasicInput";
import NormalText from "../Texts/NormalText";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { register } from "../redux/authSlice";

const Register: React.FC = () => {
  const genericForm = useGenericForm({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
  });
  const isAuthLoading = useAppSelector((app) => app.auth.isLoading);
  const dispatch = useAppDispatch();
  const snackbarOpenUse = useOpenSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (): void => {
    dispatch(
      register({
        email: genericForm.fieldValues.email,
        password: genericForm.fieldValues.password,
        firstname: genericForm.fieldValues.firstname,
        lastname: genericForm.fieldValues.lastname,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getUser())
          .unwrap()
          .then((res) => {
            navigate('/');
          })
          .catch((err) => {
            snackbarOpenUse.error("Impossible de récupérer vos informations");
          })
          .finally(() => {
            snackbarOpenUse.success("Vous êtes inscrit.");
          });
      })
      .catch((err: any) => {
        console.log(err);
        snackbarOpenUse.error("Impossible de s'enregistrer.", err);
      });
  };

  return (
    <Page pageTitle="Register" hideFooter={true} centerAllHorizontally={true}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="20px"
        marginTop="20px"
        style={{
          maxWidth: "400px",
          minWidth: "200px",
        }}
        justifySelf={"center"}
      >
        <Grid item display="grid" justifyContent={"center"}>
          <NormalText
            text="S'inscrire"
            fontSize="20px"
            color="White"
            fontWeight={600}
          />
        </Grid>
        <Grid item xs display="grid">
          <BasicInput
            title="Email"
            placeholder={""}
            {...genericForm.generateInputAttributes("email", {
              isRequired: true,
              type: "email",
            })}
          />
        </Grid>
        <Grid item xs display="grid">
          <BasicInput
            title="Nom"
            placeholder={""}
            {...genericForm.generateInputAttributes("lastname", {
              isRequired: true,
            })}
          />
        </Grid>
        <Grid item xs display="grid">
          <BasicInput
            title="Prénom"
            placeholder={""}
            {...genericForm.generateInputAttributes("firstname", {
              isRequired: true,
            })}
          />
        </Grid>
        <Grid
          item
          xs
          display="grid"
          style={{
            maxWidth: "400px",
            minWidth: "200px",
          }}
          justifySelf={"center"}
        >
          <BasicInput
            title="Mot de passe"
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                genericForm.submitValues(
                  () => handleSubmit(),
                  () => {
                    return;
                  }
                );
              }
            }}
            placeholder={""}
            type={"password"}
            {...genericForm.generateInputAttributes("password", {
              isRequired: true,
              minLength: 4,
              maxLength: 20,
            })}
          />
        </Grid>
        <Grid item display="grid" alignSelf={"center"} marginTop="5px">
          <PrimaryButton
            text="S'inscrire"
            onClick={() =>
              genericForm.submitValues(
                () => handleSubmit(),
                () => {
                  return;
                }
              )
            }
            disabled={isAuthLoading}
          />
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            gap="20px"
            justifyContent={"space-between"}
          >
            <Grid item display="grid">
              <NormalText
                text="Vous êtes déjà enregistré ?"
                onClick={() => {
                  navigate("/");
                }}
              />
            </Grid>
            <Grid item display="grid">
              <NormalText
                text="Mot de passe oublié ?"
                onClick={() => {
                  snackbarOpenUse.error(
                    "Fonctionnalité en cours de développement, elle permettra de réinitialiser son mot de passe."
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Register;
