import React from "react";
import NormalText from "../Texts/NormalText";
import { Grid } from "@mui/material";
import Colors from "../utils/Colors";
import {
  AccountCircleOutlined,
  BedtimeOutlined,
  Explore,
  ExploreOutlined,
  FavoriteBorder,
  FitnessCenter,
  Home,
  HomeOutlined,
  MusicNoteOutlined,
  PermIdentity,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "./Page";

const TextIconFooter: React.FC<{
  text: string;
  onClick: () => void;
  Icon: JSX.Element;
  isActual?: boolean;
}> = (props) => {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      alignItems={"center"}
      gap="3px"
      onClick={props.onClick}
      className="button-nobreak"
    >
      <Grid item display="grid" style={{ opacity: props.isActual ? 1 : 0.4 }}>
        {props.Icon}
      </Grid>
      <Grid item display="grid">
        <NormalText
          fontSize="10px"
          text={props.text}
          color="White"
          fontStyle="normal"
          fontWeight={400}
          onClick={() => {
            return;
          }}
          style={{
            whiteSpace: "nowrap",
            opacity: props.isActual ? 1 : 0.4,
          }}
        />
      </Grid>
    </Grid>
  );
};

const NewFooter: React.FC<{ pageTitle?: PageTitle }> = (props) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      padding="8px 5px"
      alignItems={"center"}
      justifyContent={"space-evenly"}
      style={{
        backgroundColor: Colors.Black,
        borderTop: "1px solid rgba(105, 105, 105, 0.5)",
      }}
    >
      <Grid item xs display="grid">
        <TextIconFooter
          Icon={<Home style={{ color: Colors.White, fontSize: 20 }} />}
          onClick={() => {
            navigate("/");
          }}
          text="Accueil"
          isActual={props.pageTitle === "Accueil"}
        />
      </Grid>
      <Grid item xs display="grid">
        <TextIconFooter
          Icon={
            <Explore style={{ color: Colors.White, fontSize: 20 }} />
          }
          onClick={() => {
            navigate("/lecons");
          }}
          text="Leçons"
          isActual={props.pageTitle === "Leçons"}
        />
      </Grid>
      <Grid item xs display="grid">
        <TextIconFooter
          Icon={
            <FitnessCenter style={{ color: Colors.White, fontSize: 20 }} />
          }
          onClick={() => {
            navigate("/exercices");
          }}
          text="Exercices"
          isActual={props.pageTitle === "Exercices"}
        />
      </Grid>
      <Grid item xs display="grid">
        <TextIconFooter
          Icon={
            <FavoriteBorder style={{ color: Colors.White, fontSize: 20 }} />
          }
          onClick={() => {
            navigate("/favoris");
          }}
          text="Favoris"
          isActual={props.pageTitle === "Favoris"}
        />
      </Grid>
      <Grid item xs display="grid">
        <TextIconFooter
          Icon={
            <PermIdentity
              style={{ color: Colors.White, fontSize: 20 }}
            />
          }
          onClick={() => {
            navigate("/profil");
          }}
          text="Profil"
          isActual={props.pageTitle === "Profil"}
        />
      </Grid>
    </Grid>
  );
};

export default NewFooter;
