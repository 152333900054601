import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import makeAPIRequest from "./makeAPIRequest";
import { NetworkError } from "./authSlice";

export interface IExerciceHistory {
  _id?: string;
  completedInSec: number;
  date: Date;
  exercise: {
    _id: string;
    title: string;
  };
}

export interface IUser {
  _id?: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  role: "user" | "admin";
  subscribed?: boolean;
  exercisesCompleted: IExerciceHistory[];
}

const updateUser = createAsyncThunk<
  IUser | any,
  Partial<IUser> | any,
  { rejectValue: NetworkError }
>(
  "/UPDATE_USER",
  async (payload, { rejectWithValue, fulfillWithValue }): Promise<any> => {
    try {
      await makeAPIRequest("put", "/api/v1/user", payload);
      const response = await makeAPIRequest("get", "/api/v1/user");
      return fulfillWithValue(response.data) as any;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.error);
    }
  }
);

const getUser = createAsyncThunk(
  "/USER",
  async (payload, thunkAPI): Promise<IUser> => {
    try {
      const response = await makeAPIRequest("get", "/api/v1/user");
      return thunkAPI.fulfillWithValue(response.data?.user) as any;
    } catch (err) {
      return thunkAPI.rejectWithValue(err) as any;
    }
  }
);

export interface UserState {
  user?: IUser;
  isLoading: boolean;
  error: NetworkError | undefined;
  success: boolean;
}

const initialState: UserState = {
  user: undefined,
  isLoading: false,
  error: undefined,
  success: false,
};

export const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      state.isLoading = false;
      state.user = undefined;
      state.error = undefined;
    },
  },
  extraReducers(builder: ActionReducerMapBuilder<UserState>) {
    /* Get user */
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.success = true;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });

    /* Update user */
    builder.addCase(updateUser.pending, (state) => {
      /*
            state.isLoading = true
            */
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      /*
            state.isLoading = false
            state.success = true
            */
      state.user = action.payload;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      /*
            state.isLoading = false
            state.success = false
            */
      state.error = action.error;
    });
  },
});

export const { logout } = userSlice.actions;

export { getUser, updateUser };

export default userSlice.reducer;
