import React from "react";
import Page from "../components/Page";
import { Grid } from "@mui/material";
import NormalText from "../Texts/NormalText";
import SecondaryButton from "../components/SecondaryButton/SecondaryButton";
import useAppDispatch from "../redux/useAppDispatch";
import { getUser, logout } from "../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { authActions } from "../redux/authSlice";
import useAppSelector from "../redux/useAppSelector";
import { getDurationFromSeconds } from "./Lecons";

export const formatDate = (
  date: string,
  separator: "line_return" | "space" | "à" = "line_return"
): string => {
  if (!date) {
    return "";
  }
  const splited = date.split("T");
  if (splited.length !== 2) {
    return "";
  }
  const YYYYDDMM = splited[0].split("-");
  if (YYYYDDMM.length !== 3) {
    return "";
  }
  const YYYY = YYYYDDMM[0];
  const MM = YYYYDDMM[1];
  const DD = YYYYDDMM[2];
  const HOUR_MIN_SEC = splited[1].split(":");
  if (HOUR_MIN_SEC.length < 3) {
    return "";
  }
  const HOUR = HOUR_MIN_SEC[0];
  const MIN = HOUR_MIN_SEC[1];
  const SEC = HOUR_MIN_SEC[2];
  if (SEC.length < 2) {
    return "";
  }
  const separatorConverter = {
    line_return: "\n",
    space: " ",
    à: " à ",
  };
  return `${DD}/${MM}/${YYYY}${
    separatorConverter?.[separator] ? separatorConverter[separator] : " "
  }${HOUR}:${MIN}:${SEC[0]}${SEC[1]}`;
};

const Profil: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((x) => x.user);

  React.useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  return (
    <Page pageTitle="Profil" centerAllHorizontally={true}>
      <Grid container direction="column" wrap="nowrap" alignItems={"center"}>
        <Grid item display="grid" marginTop="30px">
          <NormalText
            fontSize="16px"
            text={user?.user?.firstname + " " + user?.user?.lastname}
          />
        </Grid>
        {user?.user?.subscribed === true && (
          <Grid item display="grid" marginTop="10px">
            <NormalText
              fontSize="12px"
              text={`Vous êtes abonné, vous avez accés à tout notre contenu.`}
            />
          </Grid>
        )}
        {user?.user?.subscribed === false && (
          <Grid item display="grid" marginTop="20px">
            <NormalText
              fontSize="12px"
              text={`Vous n'êtes pas abonné, les contenus sont limités.`}
            />
          </Grid>
        )}
        <Grid item display="grid" marginTop="20px">
          <NormalText fontSize="16px" text={`Exercices complétés`} />
        </Grid>
        <Grid item display="grid" marginTop="5px">
          {user?.user?.exercisesCompleted &&
          user?.user?.exercisesCompleted?.length > 0 ? (
            <Grid container direction="column" wrap="nowrap" gap="5px">
              {user.user.exercisesCompleted.map((x, index) => {
                return (
                  <Grid item display="grid" key={x?._id}>
                    <NormalText
                      fontSize="12px"
                      text={`${formatDate(x?.date as any, "à")} - L'exercice "${
                        x?.exercise?.title
                      }" a été complété en ${getDurationFromSeconds(
                        x?.completedInSec
                      )}`}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <NormalText
              fontSize="12px"
              text={"Vous n'avez complété aucun exercice."}
            />
          )}
        </Grid>
        <Grid item display="grid" marginTop="20px">
          <SecondaryButton
            text="Se déconnecter"
            onClick={() => {
              dispatch(logout());
              dispatch(authActions.setToken(undefined));
              navigate("/");
            }}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default Profil;
