import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Accueil from "./pages/Accueil";
import Favoris from "./pages/Favoris";
import Lecons from "./pages/Lecons";
import Exercices from "./pages/Exercices";
import Profil from "./pages/Profil";
import NotFound from "./pages/NotFound";
import Register from "./pages/Register";
import useAppSelector from "./redux/useAppSelector";
import Login from "./pages/Login";
import { snackbarActions } from "./redux/snackbarSlice";
import MySnackbar from "./components/MySnackbar";
import useAppDispatch from "./redux/useAppDispatch";
import { AlertColor } from "@mui/material";
import { getUser } from "./redux/userSlice";
import Page from "./components/Page";
import Exercice from "./pages/Exercice";
import PWAInstallButton from "./components/PWAInstallButton";

const router = (isLogged = false) => {
  const defaultPage = <Accueil />;
  const unlogged = <Login />;

  return createBrowserRouter([
    {
      path: "/",
      element: isLogged ? defaultPage : <Login />,
    },
    {
      path: "/register",
      element: isLogged ? defaultPage : <Register />,
    },
    {
      path: "/favoris",
      element: isLogged ? <Favoris /> : unlogged,
    },
    {
      path: "/exercice/:id",
      element: isLogged ? <Exercice /> : unlogged,
    },
    {
      path: "/lecons",
      element: isLogged ? <Lecons /> : unlogged,
    },
    {
      path: "/exercices",
      element: isLogged ? <Exercices /> : unlogged,
    },
    {
      path: "/profil",
      element: isLogged ? <Profil /> : unlogged,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
};

const AppSnackbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const snackbarReducer = useAppSelector((state) => state.snackbar);

  return (
    <MySnackbar
      isOpen={snackbarReducer?.isOpen}
      message={snackbarReducer?.message}
      duration={snackbarReducer?.duration}
      width={snackbarReducer?.width}
      type={snackbarReducer?.type as AlertColor}
      onClose={() => dispatch(snackbarActions.closeSnackbar())}
    />
  );
};

const App: React.FC = () => {
  const token = useAppSelector((x) => x?.auth?.token);
  const dispatch = useAppDispatch();
  const user = useAppSelector((x) => x.user);

  React.useEffect(() => {
    if (token) {
      dispatch(getUser());
    }
  }, [dispatch, token]);

  if (!user?.user?._id && token && user?.isLoading) {
    return <></>;
  }
  return (
    <>
      <PWAInstallButton />
      <AppSnackbar />
      <RouterProvider
        router={router(token && user?.user?._id ? true : false)}
      />
    </>
  );
};

export default App;
