import { Grid } from "@mui/material";
import React from "react";
import Colors from "../../utils/Colors";

const MyPopup: React.FC<{
  xs: number;
  minHeight?: number;
  header: JSX.Element;
  children?: React.ReactNode;
  heightPopup?: any;
}> = (props) => {
  const { xs, minHeight, header, children, heightPopup } = props;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(5px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        className="my-popup-content"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "hidden",
          maxWidth: `${xs * 100}px`,
        }}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 10,
            backgroundColor: Colors.Black,
          }}
        >
          {header}
        </div>
        <div
          style={{
            overflow: "auto",
            flex: 1,
            padding: "16px",
            backgroundColor: Colors.White,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default MyPopup;
