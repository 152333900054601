import { Grid } from "@mui/material";
import React from "react";
import FormPopupHeader from "./FormPopupHeader";
import MyPopup from "./MyPopup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
  ImageToolbar,
  ImageCaption,
  ImageResize,
  ImageStyle,
  CloudServices,
  BlockQuote,
  Base64UploadAdapter,
  Autoformat,
  Alignment,
  ImageUpload,
  LinkImage,
  PasteFromOffice,
  TableToolbar,
  TextTransformation,
  Image,
} from "ckeditor5";

import "ckeditor5/ckeditor5.css";

export const MyEditor: React.FC<{
  data: string;
  onChange: (html: string) => void;
}> = (props) => {
  const inputHandler = (event: any, editor: any) => {
    props.onChange(editor.getData());
  };

  return (
    <CKEditor
      editor={ClassicEditor}
      onChange={inputHandler}
      data={props.data}
      config={{
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "link",
          "bulletedList",
          "numberedList",
          "alignment",
          "|",
          "outdent",
          "indent",
          "|",
          "imageUpload",
          "blockQuote",
          "insertTable",
          "mediaEmbed",
          "undo",
          "redo",
        ],
        plugins: [
          Alignment,
          Autoformat,
          Base64UploadAdapter,
          BlockQuote,
          Bold,
          CloudServices,
          Essentials,
          Heading,
          Image,
          ImageCaption,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          Italic,
          Link,
          LinkImage,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          Table,
          TableToolbar,
          TextTransformation,
        ],
        initialData: "<p></p>",
      }}
    />
  );
};

export const GenericPopup: React.FC<{
  title: { leftPart: string; rightPart: string };
  subtitle?: string;
  onClose: () => void;
  xs?: number;
  minHeight?: number;
  addCloseSecurity?: boolean;
  children?: JSX.Element;
  heightPopup?: any;
}> = (props) => {
  return (
    <>
      <MyPopup
        heightPopup={props.heightPopup}
        xs={props?.xs ? props?.xs : 9}
        minHeight={props?.minHeight ? props.minHeight : undefined}
        header={
          <FormPopupHeader
            firstTitle={props.title.leftPart}
            secondTitle={props.title.rightPart}
            onClose={() => props.onClose()}
          />
        }
      >
        <Grid container direction="column" wrap="nowrap">
          <Grid item display="grid">
            <Grid
              container
              direction="column"
              gap="15px"
              wrap="nowrap"
              style={{
                fontSize: "16px",
                color: "#0B243C",
                fontFamily: "Poppins",
              }}
            >
              {props.subtitle && (
                <Grid
                  item
                  display="grid"
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <span
                    style={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      whiteSpace: "pre-line",
                      textAlign: "center",
                    }}
                  >
                    {props.subtitle}
                  </span>
                </Grid>
              )}
              <Grid item display="grid">
                {props.children}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MyPopup>
    </>
  );
};
