import { Grid } from "@mui/material";
import * as React from "react";
import ButtonIconWithTooltip from "../ButtonIconWithTooltip";
import NormalText from "../../Texts/NormalText";
import Colors from "../../utils/Colors";
import { CloseRounded } from "@mui/icons-material";

interface IFormPopupHeader {
  firstTitle: string;
  secondTitle: string;
  onClose: any;
}

const FormPopupHeader = (props: IFormPopupHeader) => {
  const { firstTitle, secondTitle, onClose } = props;

  return (
    <Grid
      container
      direction="row"
      wrap="nowrap"
      gap="18px"
      alignItems={"center"}
      justifyContent={"space-between"}
      className="form-popup-header"
      style={{
        paddingTop: "28px",
        paddingBottom: "28px",
        paddingRight: "34px",
        paddingLeft: "46px",
        backgroundColor: Colors.Black,
      }}
    >
      <Grid item display="grid">
        <Grid container direction="row" wrap="nowrap" gap="7px">
          <Grid item display="grid">
            <NormalText
              text={firstTitle}
              fontSize="24px"
              fontWeight={600}
              color={"White"}
            />
          </Grid>
          <Grid item display="grid">
            <NormalText
              text={secondTitle}
              fontSize="24px"
              fontWeight={600}
              color={"White"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="grid">
        <ButtonIconWithTooltip
          Icon={<CloseRounded style={{
            color: Colors.White,
          }} />}
          tooltipText=""
          onClick={() => onClose()}
        />
      </Grid>
    </Grid>
  );
};

export default FormPopupHeader;
