import React, { useState } from "react";
import Colors from "../utils/Colors";
import { nanoid } from "nanoid";

const MySwitch: React.FC<{
  checked: boolean;
  handleChange: () => void;
}> = (props) => {
  const randomId = nanoid();
  const switchContainerStyle: React.CSSProperties = {
    display: "inline-block",
    position: "relative",
    width: "40px",
    height: "20px",
  };

  const switchInputStyle: React.CSSProperties = {
    display: "none",
  };

  const switchLabelStyle: React.CSSProperties = {
    display: "block",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    position: "relative",
    backgroundColor: Colors.White,
  };

  const switchInnerStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: props.checked ? "calc(100% - 18px)" : "0",
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    backgroundColor: "black",
    transition: "left 0.3s",
  };

  return (
    <div style={switchContainerStyle}>
      <input
        type="checkbox"
        id={randomId}
        style={switchInputStyle}
        checked={props.checked}
        onChange={props.handleChange}
      />
      <label htmlFor={randomId} style={switchLabelStyle}>
        <div style={{...switchInnerStyle, backgroundColor: props.checked ? "green" : "red"}}></div>
      </label>
    </div>
  );
};

export default MySwitch;
