import React from "react";
import Page from "../components/Page";
import { Grid } from "@mui/material";
import useGenericForm from "../redux/useGenericForm";
import useAppSelector from "../redux/useAppSelector";
import useAppDispatch from "../redux/useAppDispatch";
import { getUser } from "../redux/userSlice";
import { login } from "../redux/authSlice";
import useOpenSnackbar from "../redux/useOpenSnackbar";
import BasicInput from "../components/BasicInput";
import NormalText from "../Texts/NormalText";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";

const Login: React.FC = () => {
  const genericForm = useGenericForm({
    email: "",
    password: "",
  });
  const isAuthLoading = useAppSelector((app) => app.auth.isLoading);
  const dispatch = useAppDispatch();
  const snackbarOpenUse = useOpenSnackbar();
  const navigate = useNavigate();

  const handleSubmit = (): void => {
    dispatch(
      login({
        email: genericForm.fieldValues.email,
        password: genericForm.fieldValues.password,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(getUser());
        snackbarOpenUse.success("Vous êtes connecté.");
      })
      .catch((err: any) => {
        console.log(err);
        snackbarOpenUse.error(
          "Le mot de passe ou l'e-mail est incorrect.",
          err
        );
      });
  };

  return (
    <Page pageTitle="Login" hideFooter={true} centerAllHorizontally={true}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="20px"
        marginTop="20px"
        style={{
          maxWidth: "400px",
          minWidth: "200px",
        }}
        justifySelf={"center"}
      >
        <Grid item display="grid" justifyContent={"center"}>
          <NormalText
            text="Se connecter"
            fontSize="20px"
            color="White"
            fontWeight={600}
          />
        </Grid>
        <Grid item xs display="grid">
          <BasicInput
            title="Email"
            placeholder={""}
            {...genericForm.generateInputAttributes("email", {
              isRequired: true,
              type: "email",
            })}
          />
        </Grid>
        <Grid
          item
          xs
          display="grid"
          style={{
            maxWidth: "400px",
            minWidth: "200px",
          }}
          justifySelf={"center"}
        >
          <BasicInput
            title="Mot de passe"
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                genericForm.submitValues(
                  () => handleSubmit(),
                  () => {
                    return;
                  }
                );
              }
            }}
            placeholder={""}
            type={"password"}
            {...genericForm.generateInputAttributes("password", {
              isRequired: true,
              minLength: 4,
              maxLength: 20,
            })}
          />
        </Grid>
        <Grid item display="grid" alignSelf={"center"} marginTop="5px">
          <PrimaryButton
            text="Se connecter"
            onClick={() =>
              genericForm.submitValues(
                () => handleSubmit(),
                () => {
                  return;
                }
              )
            }
            disabled={isAuthLoading}
          />
        </Grid>
        <Grid item display="grid">
          <Grid container direction="row" gap="20px" justifyContent={"space-between"}>
            <Grid item display="grid">
              <NormalText text="Vous n'avez pas de compte ?" onClick={() => {
                navigate('/register');
              }} />
            </Grid>
            <Grid item display="grid">
              <NormalText text="Mot de passe oublié ?" onClick={() => {
                snackbarOpenUse.error("Fonctionnalité en cours de développement, elle permettra de réinitialiser son mot de passe.")
              }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Login;
