import { Grid } from "@mui/material";
import React from "react";
import "./BasicSelect.scss";
import NormalText from "../Texts/NormalText";
import { ColorList } from "../utils/Colors";

export interface IBasicSelect
  extends React.InputHTMLAttributes<HTMLSelectElement> {
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  options: Array<{ label: string; value: string }>;
  colorTitle?: ColorList;
  centerTitle?: boolean;
}

const BasicSelect: React.FunctionComponent<IBasicSelect> = (
  props: IBasicSelect
): JSX.Element => {
  const { className, style, colorTitle, centerTitle } = props;

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      gap="5px"
      alignItems={centerTitle ? "center" : undefined}
    >
      {props.title && (
        <Grid item display="grid">
          <NormalText
            text={props.title}
            color={colorTitle ? colorTitle : "White"}
            fontSize="14px"
          />
        </Grid>
      )}
      <Grid item display="grid">
        <Grid container direction="column" wrap="nowrap">
          <Grid item display="grid">
            <select {...props} style={style} className={`${className}`}>
              <option value={""} disabled={true}>
                {"Non renseigné"}
              </option>
              {props.options.map((opt, index) => {
                return (
                  <option key={index + 1} value={opt.value}>
                    {opt.label}
                  </option>
                );
              })}
            </select>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

BasicSelect.defaultProps = {
  className: "basic-select",
  style: undefined,
  title: undefined,
};

export default BasicSelect;
