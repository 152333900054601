import { useDispatch } from "react-redux";
import { snackbarActions } from "./snackbarSlice";

const useOpenSnackbar = () => {
    const dispatch = useDispatch();
  
    return {
      error: (message: string, error?: any, options?: { duration?: 3000 }) => {
        let msg = message;
        if (error?.response?.data?.popupMessage) {
          msg = error.response.data.popupMessage;
        }
        if (error?.response?.data?.errors?.[0]?.msg) {
          msg = error.response.data.errors[0].msg;
        }
        dispatch(
          snackbarActions.openSnackbar({
            message: msg,
            duration: options?.duration ? options.duration : 3000,
            type: "error",
          })
        );
      },
      success: (
        message: string,
        response?: any,
        options?: { duration?: 3000 }
      ) => {
        let msg = message;
        if (response?.data?.popupMessage) {
          msg = response.data.popupMessage;
        }
        dispatch(
          snackbarActions.openSnackbar({
            message: msg,
            duration: options?.duration ? options.duration : 3000,
            type: "success",
          })
        );
      },
    };
  };
  
  export default useOpenSnackbar;