import { configureStore } from '@reduxjs/toolkit'
import userSlice from './userSlice'
import authSlice from './authSlice'
import snackbarSlice from './snackbarSlice'
import windowSlice from './windowSlice'
import leconsSlice from './leconsSlice'
import exercisesSlice from './exercisesSlice'

export const store = configureStore({
    reducer: {
        auth: authSlice,
        user: userSlice,
        snackbar: snackbarSlice,
        window: windowSlice,
        lecons: leconsSlice,
        exercises: exercisesSlice,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
