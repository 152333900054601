import React from 'react'
import './PrimaryButton.scss'

interface IPrimaryButton
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string
    className?: string
    style?: React.CSSProperties
}

const PrimaryButton: React.FunctionComponent<
    IPrimaryButton
> = (props: IPrimaryButton): JSX.Element => {
    const { className, style, text, ...rest } = props

    return (
        <button style={style} className={className} {...rest}>
            {text}
        </button>
    )
}

PrimaryButton.defaultProps = {
    className: 'primary-button',
    style: undefined,
}

export default PrimaryButton
