import {
  ActionReducerMapBuilder,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axios from "axios";

export interface NetworkError {
  message?: string;
  data?: unknown;
  status?: number;
}

export interface LoginReply {
  refresh_token: string;
  access_token: string;
  expires_in: string;
  token_type: string;
}

export interface LoginBody {
  email: string;
  password: string;
}

const login = createAsyncThunk<
  LoginReply,
  LoginBody,
  {
    rejectValue: NetworkError;
  }
>("Auth/LOGIN", async (payload, thunkAPI): Promise<any> => {
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.post(`${API_URL}/api/v1/login`, {
      email: payload.email,
      password: payload.password,
    });
    return thunkAPI.fulfillWithValue(response.data as LoginReply);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err?.response?.data?.error);
  }
});

interface RegisterReply {
  refresh_token: string;
  access_token: string;
  expires_in: string;
  token_type: string;
}

interface RegisterBody {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
}

const register = createAsyncThunk<
  RegisterReply,
  RegisterBody,
  {
    rejectValue: NetworkError;
  }
>("Auth/REGISTER", async (payload, thunkAPI): Promise<any> => {
  const API_URL = process.env.REACT_APP_API_URL;
  try {
    const response = await axios.post(`${API_URL}/api/v1/register`, {
      email: payload.email,
      password: payload.password,
      firstname: payload.firstname,
      lastname: payload.lastname,
    });
    return thunkAPI.fulfillWithValue(response.data as RegisterReply);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err?.response?.data?.error);
  }
});

const setToken = (state: AuthState, token: string | undefined) => {
  if (token) {
    localStorage.setItem("token", token);
    state.token = token;
  } else {
    localStorage.removeItem("token");
    state.token = undefined;
  }
};

interface AuthState {
  token: string | undefined;
  socket?: WebSocket;
  refreshToken?: string;
  isLoading: boolean;
  error: NetworkError | undefined;
  success: boolean;
}

const tokenInStorage = localStorage.getItem("token");
const initialState: AuthState = {
  token: tokenInStorage ? tokenInStorage : undefined,
  socket: undefined,
  refreshToken: undefined,
  isLoading: false,
  error: undefined,
  success: false,
};

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setSocket: (state, action) => {
      if (action?.payload?.socket) {
        state.socket = action.payload.socket;
      }
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers(builder: ActionReducerMapBuilder<AuthState>) {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      setToken(
        state,
        "refresh_token" in action.payload
          ? action.payload.refresh_token
          : undefined
      );
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
      localStorage.removeItem("token");
    });
    builder.addCase(register.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      setToken(
        state,
        "refresh_token" in action.payload
          ? action.payload.refresh_token
          : undefined
      );
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
      state.token = undefined;
      localStorage.removeItem("token");
    });
  },
});

export const authActions = authSlice.actions;
export { login, register };
export default authSlice.reducer;
